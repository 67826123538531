<template>
  <b-overlay
    variant="white"
    :show="isLoading || isLoadings"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <section>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="6" class="mb-2">
            <h6>Nomor SPPD</h6>
            <p class="card-text mb-25">{{ sppd.sppd_number || '-' }}</p>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>Menunjuk Surat</h6>
            <b-link
              v-if="sppd.letter"
              :href="sppd.letter_url"
              class="text-info"
              target="_blank"
            >
              {{ sppd.letter }}
            </b-link>
            <b-form v-else>
              <b-form-group>
                <b-form-file
                  v-model="letter"
                  :state="Boolean(letter)"
                  :placeholder="placeholderMenunjukSurat"
                  drop-placeholder="Taruh file disini..."
                  accept=".jpg, .png, .gif, .doc, .docx, .pdf"
                  @change="handleFileUpload($event)"
                ></b-form-file>
                <b-button ref="resetLetter" type="reset" hidden></b-button>
                <p class="text-gray mb-0" style="font-size: 0.857rem">Unggah file dalam format JPG, PNG, GIF, PDF, DOCX, atau DOC (batas maks. 10 MB).</p>
              </b-form-group>
            </b-form>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>Nomor Surat</h6>
            <p v-if="sppd.letter_number" class="card-text mb-25">
              {{ sppd.letter_number }}
            </p>
            <b-form-input
              v-else
              v-model="model.letter_number"
              placeholder="Masukkan Nomor Surat"
              :formatter="letterNumberFormatter"
            ></b-form-input>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>Tanggal Surat</h6>
            <p v-if="sppd.letter_date" class="card-text mb-25">
              {{ sppd.letter_date | moment('DD MMMM YYYY') }}
            </p>
            <b-form-datepicker
              v-else
              v-model="model.letter_date"
              locale="id"
              :max="new Date()"
              placeholder="Pilih Tanggal Surat"
            ></b-form-datepicker>
          </b-col>
          <b-col cols="12" class="mb-2">
            <h6>Perihal</h6>
            <b-form-textarea
              v-model="model.enclosure"
              placeholder="Masukkan perihal..."
              class="mb-1"
              rows="3"
              max-rows="6"
              :disabled="Boolean(sppd.enclosure)"
            ></b-form-textarea>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>
              {{ sppd.type_anggaran	=== 'pusat' ? 'Sub Direktorat' : 'Regional' }}
            </h6>
            <p class="card-text mb-25">
              {{
                sppd.type_anggaran === 'pusat' ? sppd.name_subdirektorat : sppd.name_regional
              }}
            </p>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6> 
              Type Project
            </h6>
            <p class="card-text mb-25">
              {{ sppd.project_type	 || '-' }}
            </p>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>
              Nama /
              {{ employee.type === 'pegawai' ? 'NIPPOS' : 'NIK' }}
            </h6>
            <p class="card-text mb-25">
              {{ sppd.employee_name || '-' }} /
              {{
                employee.type === 'pegawai' ? employee.employee : employee.nik
              }}
            </p>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>NPWP</h6>
            <p class="card-text mb-25">{{ employee.npwp || '-' }}</p>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>Jabatan</h6>
            <p class="card-text mb-25">
              {{
                employee.type === 'pegawai'
                  ? employee.position
                  : employee.position
              }}
            </p>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>Bagian/Unit Kerja</h6>
            <p class="card-text mb-25">
              {{ employee.divisi || '-' }}
            </p>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>Kantor Asal</h6>
            <p class="card-text mb-25">{{ sppd.origin_office || '-' }}</p>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>Kantor/Kota Tujuan</h6>
            <p class="card-text mb-25">{{ sppd.destination_office || '-' }}</p>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>Tanggal Berangkat</h6>
            <p class="card-text mb-25">
              {{ sppd.departure_date | moment('DD MMMM YYYY') }}
            </p>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>Tanggal Kembali</h6>
            <p class="card-text mb-25">
              {{ sppd.return_date | moment('DD MMMM YYYY') }}
            </p>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>Lama Perjalanan Dinas</h6>
            <p class="card-text mb-25">{{ sppd.total_days || 0 }} hari</p>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>Jenis Perjalanan Dinas</h6>
            <p class="card-text mb-25">{{ sppdType }}</p>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>Jenis Fasilitas Perjalanan Dinas</h6>
            <p class="card-text mb-25">
              {{ sppdFacilityType }}
            </p>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>Alat Transportasi</h6>
            <p class="card-text mb-25">{{ transportation }}</p>
          </b-col>
          <b-col v-if="mustUpload" cols="12" md="6" class="mb-2">
            <h6>
              {{
                sppd.permission_letter
                  ? 'Surat Izin'
                  : 'Upload Surat Izin Apabila Melebihi 9 Hari SPJ'
              }}
            </h6>
            <b-link
              v-if="sppd.permission_letter"
              :href="sppd.permission_letter_url"
              class="text-info"
              target="_blank"
            >
              {{ sppd.permission_letter }}
            </b-link>
            <b-form-file
              v-else
              v-model="permissionLetter"
              :placeholder="placeholderSuratIzin"
              drop-placeholder="Taruh file disini..."
              accept=".jpg, .png, .gif, .doc, .docx, .pdf"
              @change="handleUploadSuratIzin($event)"
            ></b-form-file>
            <p class="text-gray mb-0" style="font-size: 0.857rem">Unggah file dalam format JPG, PNG, GIF, PDF, DOCX, atau DOC (batas maks. 10 MB).</p>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>Maksud Perjalanan Dinas</h6>
            <p class="card-text mb-25">{{ sppd.purpose || '-' }}</p>
          </b-col>
          <b-col cols="12" class="mb-2">
            <h6>Catatan Hitungan Hari</h6>
            <b-form-textarea
              placeholder="Masukkan catatan hitungan hari..."
              class="mb-1"
              rows="3"
              max-rows="6"
              disabled
              :value="catatanHitunganHari"
            ></b-form-textarea>
            <!-- v-model="total_days_used_current_month" -->
          </b-col>
          <!-- <b-col cols="12" class="mb-2">
          <h6>Catatan Lainnya</h6>
          <b-form-textarea
            placeholder="Tidak ada catatan lainnya"
            class="mb-1"
            rows="3"
            max-rows="6"
            :value="sppd.note"
            disabled
          ></b-form-textarea>
        </b-col> -->
          <b-col cols="12" md="6" class="mb-2">
            <h6>Atasan / Yang Mengajukan</h6>
            <p class="card-text mb-25">
              {{ sppd.submitter.split("|")[0].trim() || '-' }} / {{ sppd.submitter_id || '-' }} /
              {{ sppd.submitter_jabatan || '-' }}
            </p>
            <span v-if="sppd.approval1_date" class="font-italic">
              *Disetujui pada
              {{ sppd.approval1_date | moment('DD/MM/YYYY HH:mm:ss') }}
            </span>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>Yang Memberi Perintah</h6>
            <p class="card-text mb-25">
              {{ sppd.instructor.split("|")[0].trim() || '-' }} / {{ sppd.instructor_id || '-' }} /
              {{ sppd.instructor_jabatan || '-' }}
            </p>
            <span v-if="sppd.approval2_date" class="font-italic">
              *Disetujui pada
              {{ sppd.approval2_date | moment('DD/MM/YYYY HH:mm:ss') }}
            </span>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>Status</h6>
            <p class="card-text mb-25">{{ sppd.general_status || '-' }}</p>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>Type Anggaran</h6>
            <p class="card-text mb-25">{{ sppd.type_anggaran || '-' }}</p>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <h6>Type</h6>
            <p class="card-text mb-25">{{ sppd.project_type || '-' }}</p>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer align="right">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          class="mr-1"
          variant="outline-secondary"
          :to="{ name: 'manajemen-sppd' }"
        >
          Kembali
        </b-button>
        <b-button
          v-if="
            sppd.general_status !== 'Dibatalkan' &&
            can('print/manajemen-sppd') &&
            !mustApproved1 &&
            !mustApproved2
          "
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          class="mr-1"
          variant="primary"
          @click="print"
        >
          Cetak
        </b-button>
        <b-button
          v-if="mustApproved1"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          class="mr-1"
          variant="success"
          @click="confirmApprove(sppd.id, 1)"
        >
          Setujui
        </b-button>
        <b-button
          v-if="mustApproved2"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          class="mr-1"
          variant="success"
          @click="confirmApprove(sppd.id, 2)"
        >
          Setujui
        </b-button>
        <b-button
          v-if="mustUpload && !sppd.permission_letter"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          class="mr-1"
          variant="primary"
          :disabled="!permissionLetterFilename || loadingUpload"
          @click="uploadPermissionLetter"
        >
          Upload
        </b-button>
        <b-button
          v-if="
            !sppd.letter ||
            !sppd.letter_number ||
            !sppd.letter_date ||
            !sppd.enclosure
          "
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          class="mr-1"
          variant="primary"
          @click="save"
        >
          Simpan
        </b-button>
      </b-card-footer>
    </section>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCardBody,
  BCardFooter,
  BLink,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormTextarea,
  BFormDatepicker,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import moment from 'moment'

import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCardBody,
    BCardFooter,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BFormDatepicker,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    sppd: {
      type: Object,
      default: () => ({}),
    },
    isLoadings: false,
  },
  data() {
    return {
      config: {
        api: '/sppd',
        redirect: 'manajemen-sppd',
      },
      model: {
        letter: null,
        letter_number: null,
        letter_date: null,
        enclosure: null,
      },
      user: getUserData(),
      permissionLetter: null,
      placeholderMenunjukSurat: 'Pilih file disini...',
      placeholderSuratIzin: 'Pilih file disini...',
      permissionLetterFilename: '',
      months: [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
      ],
      loadingUpload: false,
      mustUpload: false,
      letter: null,
      isLoading: false,
    }
  },
  computed: {
    employee() {
      return this.sppd.employee || {}
    },
    total_days_used_current_month() {
      return this.sppd.total_days_used_current_month
    },
    sppdType() {
      if (this.sppd.sppd_type_id == 1) {
        return 'Jabatan'
      }
      if (this.sppd.sppd_type_id == 2) {
        return 'Pemeriksaan'
      }
      if (this.sppd.sppd_type_id == 3) {
        return 'Pindah'
      }
      return '-'
    },
    sppdFacilityType() {
      if (this.sppd.sppd_facility_type_id == 1) {
        return 'Perjalanan Dinas Biasa'
      }
      if (this.sppd.sppd_facility_type_id == 2) {
        return 'Perjalanan Dinas Fasilitas Khusus'
      }
      return '-'
    },
    transportation() {
      if (this.sppd.transportation == 1) {
        return 'Dinas'
      }
      if (this.sppd.transportation == 2) {
        return 'Pribadi'
      }
      if (this.sppd.transportation == 3) {
        return 'Umum'
      }
      return '-'
    },
    catatanHitunganHari() {
      if (this.sppd.remain_days?.length > 0) {
        let text = ''

        this.sppd.remain_days.forEach(
          ({
            days,
            days_remainder,
            total_days_used_current_month,
            periode,
          }) => {
            if (days_remainder < 0) {
              this.mustUpload = true
            }

            const periodeText = moment(periode).format('MMMM YYYY')
            text += `Perjalanan bulan ${periodeText} : ${total_days_used_current_month} hari\nPerjalanan ini : ${days} hari\nTotal perjalanan bulan ${periodeText} : ${
              parseInt(total_days_used_current_month, 10) + parseInt(days, 10)
            } hari\nSisa perjalanan bulan ${periodeText} : ${days_remainder} hari\n\n`
          },
        )
        text += 'Maksimal Perjalanan Dinas 9 hari'
        return text
      }

      return '-'
    },
    mustApproved1() {
      return (
        this.sppd?.general_status?.toLowerCase() === 'menunggu approval 1' &&
        (this.user.nippos == this.sppd?.submitter_id ||
          this.user?.nik == this.sppd?.submitter_id) &&
        !this.sppd?.approve1
      )
    },
    mustApproved2() {
      return (
        (this.sppd?.general_status?.toLowerCase() === 'menunggu approval 2' ||
          (this.sppd?.general_status?.toLowerCase() ===
            'sudah melakukan perjalanan' &&
            !this.sppd?.approve2)) &&
        (this.user?.nippos == this.sppd?.instructor_id ||
          this.user?.nik == this.sppd?.instructor_id)
      )
    },
  },
  watch: {
    sppd(newValue) {
      this.model = {
        letter: newValue.letter,
        letter_number: newValue.letter_number,
        letter_date: newValue.letter_date,
        enclosure: newValue.enclosure,
      }
    },
  },
  methods: {
    letterNumberFormatter(value) {
      if (value.length > 255) {
        this.letterNumberErrorMessage = 'Nomor surat maksimal 255 karakter.'
        return value.substr(0, 255)
      }
      this.letterNumberErrorMessage = ''
      return value
    },
    handleUploadSuratIzin(event) {
      this.loadingUpload = true
      const file = event.target.files[0]

      if (file.size > 10 * 1024 * 1024) {
        this.$refs.resetLetter.click()
        this.letter = null
        this.model.letter = null
        this.placeholderFile = 'Pilih file disini...'
        this.$bvToast.toast(
          'Ukuran File Melebihi 10 MB, Silahkan Upload Kembali dengan ukuran file yang sesuai',
          {
            title: 'Tambah Surat Izin',
            variant: 'danger',
            solid: true,
          },
        )

        return
      }

      const formData = new FormData()
      formData.append('file', file)
      formData.append('type', 'surat izin')

      axios
        .post('/misc/upload-file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(resp => {
          this.loadingUpload = false
          this.permissionLetterFilename = resp.data.filename
        })
        .catch(error => {
          this.loadingUpload = false
          this.$bvToast.toast(error.message, {
            title: 'Detail SPPD',
            variant: 'danger',
            solid: true,
          })
        })
    },
    uploadPermissionLetter() {
      const { id, enclosure, note } = this.sppd
      const data = {
        id,
        enclosure,
        note,
        permission_letter: this.permissionLetterFilename,
      }
      axios
        .post(`${this.config.api}/detail_info`, data)
        .then(() => {
          this.$router.push({
            name: this.config.redirect,
            params: {
              event: 'success',
              title: 'Berhasil',
              text: 'Surat izin berhasil diunggah',
            },
          })
        })
        .catch(error => {
          this.$bvToast.toast(error.message, {
            title: 'Detail SPPD',
            variant: 'danger',
            solid: true,
          })
        })
    },
    save() {
      const data = {
        ...this.model,
        id: this.sppd.id,
      }
      this.isLoading = true
      axios
        .patch(this.config.api, data)
        .then(() => {
          this.isLoading = false
          this.$emit('refresh-sppd')
          this.$bvToast.toast('Berhasil update data SPPD', {
            title: 'Detail SPPD',
            variant: 'success',
            solid: true,
          })
        })
        .catch(error => {
          this.isLoading = false
          this.$bvToast.toast(error.message, {
            title: 'Detail SPPD',
            variant: 'danger',
            solid: true,
          })
        })
    },
    print() {
      axios
        .get(`${this.config.api}/print/${this.sppd.id}`, {
          responseType: 'blob',
        })
        .then(res => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: 'application/pdf' }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `SPPD-${this.sppd.sppd_number}.pdf`)
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
        .catch(error => {
          console.error(error)

          var message = "";
          if(typeof error.response.data.message === 'object'){
            message = error.response.data.message.errors[0].msg
          } else {
            message = error.response.data.message
          }
          this.makeToast('danger', "Terjadi Kesalahan", message)
        });
    },
    confirmApprove(id, approveType) {
      this.$swal({
        title: 'Apakah Anda Yakin',
        text: 'untuk menyetujui SPPD tersebut?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true

          const url = `${this.config.api}/approve_${approveType}`
          const data = { id }
          axios
            .post(url, data)
            .then(() => {
              this.isLoading = false
              this.$swal({
                icon: 'success',
                title: 'Berhasil Disetujui',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.$emit('refresh-sppd')
            })
            .catch(error => {
              this.isLoading = false
              var message = "";
              if(typeof error.response.data.message === 'object'){
                message = error.response.data.message.errors[0].msg
              } else {
                message = error.response.data.message
              }
              this.$swal({
                icon: 'Danger',
                title: 'Terjadi kesalahan!',
                text:
                  err.response?.status === 422
                    ? err.response?.data.message
                    : message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    },
    async handleFileUpload(event) {
      const file = event.target.files[0]
      if (file) {
        if (file.size > 10 * 1024 * 1024) {
          this.$refs.resetLetter.click()
          this.letter = null
          this.placeholderMenunjukSurat = 'Pilih file disini...'
          this.$bvToast.toast(
            'Ukuran File Melebihi 10 MB, Silahkan Upload Kembali dengan ukuran file yang sesuai',
            {
              title: 'Detail SPPD',
              variant: 'danger',
              solid: true,
            },
          )

          return
        }

        this.placeholderMenunjukSurat = file.name
        const formData = new FormData()
        formData.append('file', file)
        formData.append('type', 'surat sppd')

        this.isLoading = true
        try {
          const resUpload = await axios.post('/misc/upload-file', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          this.isLoading = false
          this.model.letter = resUpload.data.filename
        } catch (error) {
          this.isLoading = false
          this.$bvToast.toast(error.message, {
            title: 'Upload Surat Perintah',
            variant: 'danger',
            solid: true,
          })
        }
      }
    },
  },
}
</script>
